import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const appConfig = useRuntimeConfig();

  if (appConfig.public?.NODE_ENV !== 'development' && appConfig.public?.NODE_ENV !== 'dev') {
    Sentry.init({
      app: [vueApp],
      dsn: appConfig.public.SENTRY_DSN,
      environment: appConfig.public?.NODE_ENV || '',
      integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: appConfig.public?.NODE_ENV === 'production' ? 0.01 : 1.0,
      tracePropagationTargets: ['localhost', /^https:\/\/www\.hostelworld\.com/],
      sampleRate: appConfig.public?.NODE_ENV === 'production' ? 0.01 : 1.0,
      release: appConfig?.public?.RELEASE_VERSION || 'staticweb',
      allowUrls: [/^https?:\/\/(.*)\.hostelworld\.com/],
      denyUrls: [
        /graph\.facebook\.com/i, // Facebook flakiness
        /connect\.facebook\.net\/en_US\/all\.js/i, // Facebook blocked
        /eatdifferent\.com\.woopra-ns\.com/i, // Woopra flakiness
        /static\.woopra\.com\/js\/woopra\.js/i,

        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,

        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /script\.hotjar\.com\//i,
        /www\.google-analytics\.com\//i,
      ],

      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          console.error(`[Exception handled by Sentry]: (${hint.originalException})`, {
            event,
            hint,
          });
        }
        // Continue sending to Sentry
        return event;
      },
    });

    vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
      }),
    );
    Sentry.attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  }
});
