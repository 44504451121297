export const userLoggedStatus = {
  loggedIn: 'logged in',
  loggedOut: 'logged out',
};

export const pricesPerDatesValues = {
  0: 'Tonight',
  1: 'This weekend',
  2: 'Next weekend',
};

export const propertyCardsValues = {
  topProperties: 'Top Hostels',
  cityCenterProperties: 'Hostels in City Center',
  propertiesWithPrivates: 'Hostels with Private Rooms',
  allProperties: 'All Hostels',
};

export const showMoreValues = {
  topProperties: 'Top Hostels',
  cityCenterProperties: 'Hostels in City Center',
  propertiesWithPrivates: 'Hostels with Private Rooms',
  allProperties: 'All Hostels',
};

export const TRACKING_EMPTY = '-1';
