import { GoogleTagManager } from '@/tracking/GoogleTagManager';
import { Segment } from '@/tracking/SegmentJs';

import TrackerEvents from '@/tracking/trackerEvents.js';
import TrackerPages from '@/tracking/trackerPages.js';

export default defineNuxtPlugin((nuxtApp) => {
  const trackers = {
    gtm: GoogleTagManager(),
    segment: Segment(),
  };

  if (process.client) {
    return {
      provide: {
        tracking: {
          ...TrackerPages(trackers, nuxtApp),
          ...TrackerEvents(trackers, nuxtApp),
        },
      },
    };
  }
});
