export const GA_ACCOUNTS = [
  {
    ID: 'HA-100372930',
    ATTR: { conversion_cookie_prefix: '_ha' },
  },
];

export const GA4_ACCOUNT = 'G-YWJRQBMT7V';

export const SEGMENT_PAGE_DATA = {
  homepage: {
    TYPE: 'homepage',
    VIEWED: 'Homepage',
    EVENT: 'Homepage Event',
  },
  worldwide: {
    TYPE: 'propertyLanding',
    VIEWED: 'Other Static Page Viewed',
    EVENT: 'Other Static Page Event',
  },
  continent: {
    TYPE: 'continentLanding',
    VIEWED: 'Other Static Page Viewed',
    EVENT: 'Other Static Page Event',
  },
  country: {
    TYPE: 'countryLanding',
    VIEWED: 'Other Static Page Viewed',
    EVENT: 'Other Static Page Event',
  },
  area: {
    TYPE: 'areaLanding',
    VIEWED: 'Other Static Page Viewed',
    EVENT: 'Other Static Page Event',
  },
  region: {
    TYPE: 'regionLanding',
    VIEWED: 'Other Static Page Viewed',
    EVENT: 'Other Static Page Event',
  },
  city: {
    TYPE: 'staticFab',
    VIEWED: 'Destination Landing Page Viewed',
    EVENT: 'Destination Landing Page Event',
  },
  district: {
    TYPE: 'staticFab',
    VIEWED: 'District Landing Page Viewed',
    EVENT: 'District Landing Page Event',
  },
  property: {
    TYPE: 'staticMicrosite',
    VIEWED: 'Property Landing Page Viewed',
    EVENT: 'Property Landing Page Event',
  },
  other: {
    TYPE: 'other',
    VIEWED: 'Other Static Page Viewed',
    EVENT: 'Other Static Page Event',
  },
};
