export function checkGdpr() {
  let isTrackingPermitted = false;
  const gdpr_allow_all = ['permit 1,2,3'];
  const gdpr_allow_functional_only = ['permit 1,2 functional'];

  const cmapiPrivacyCookie = useCookie('cmapi_cookie_privacy') || '';
  const noticeBehaviorCookie = useCookie('notice_behavior') || '';
  const behaviour = noticeBehaviorCookie?.value === 'implied,eu';

  const cmapiPrivacyCookieAllowed = [...gdpr_allow_all, ...gdpr_allow_functional_only].includes(
    cmapiPrivacyCookie?.value,
  );

  isTrackingPermitted = !behaviour || (behaviour && cmapiPrivacyCookieAllowed);

  return { isTrackingAllowed: process.client && isTrackingPermitted };
}
