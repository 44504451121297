import { getCommonTracking } from '@/tracking/common';

export default function TrackerPages(trackers, nuxtApp) {
  const {
    getGtmOptions,
    getSegmentOptions,
  } = getCommonTracking(nuxtApp);

  return {
    trackOnPageLoad: (dataLayerVariables, segmentVariables) => {
      trackers?.gtm?.onPageView({
        ...getGtmOptions,
        ...dataLayerVariables,
      });

      trackers?.segment?.onPageView({
        ...getSegmentOptions,
        ...segmentVariables,
      });

      // @NOTE: STATICPWA-1035 - Because of tracking for Segment, we need to send both page views and events
      if (segmentVariables?.event_name !== 'Other Static Page Viewed') {
        trackers?.segment?.onEvent({
          ...getSegmentOptions,
          ...segmentVariables,
        });
      }
    },
  };
}
