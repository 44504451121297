import { entryTypes } from '@/config/entry-pages.js';
import { cookies } from '@/lib/cookies.js';

const storage = window?.localStorage;

const hasLocalStorage = !!window?.localStorage;

const urlParams = new URLSearchParams(window?.location?.search);

const routePath = window?.location?.pathname;

const storageURLParameters = [
  'source',
  'affiliate',
  'aff_userid',
  'network',
  'campaign_id',
  'adgroup_id',
  'criteria_id',
  'creative',
  'locationid',
  'location_physical_id',
  'location_interest_id',
  'ad_position',
  'uniqueclickID',
  'sub_keyword',
  'sub_ad',
  'sub_publisher',
  'gclsrc',
  'auid',
  'keyword',
  'gclid',
  'msclkid',
  'matchtype',
  'adType',
  'hotel_id',
  'utm_content',
  'utm_source',
  'hpa_u_list_id',
];
const REFERRER = 'referrer';
const ENTRY_PAGE = 'entrypage';

const DEFAULT_VALUE = '-1';

let ENTRY_BLOG_PAGE = false;

const cookieName = 'channelAttributionData';

function getCookieDomain() {
  return window?.__NUXT__?.config?.public?.COOKIE_DOMAIN || '.hostelworld.com';
}

function getReferrer(referrer) {
  if (referrer) {
    if (referrer?.match(/^https?:\/\/(.*)\.hostelworld\.com\/blog(.*)(\/|$)/i)) {
      ENTRY_BLOG_PAGE = true;
      return referrer;
    }

    if (!referrer?.match(/^https?:\/\/(.*)\.hostelworld\.com/)) {
      if (!referrer?.includes('-brand')) {
        const pageLocation = routePath === '/' ? '-brand' : '';
        return referrer + pageLocation;
      }
      return referrer;
    }
    return;
  }
  return DEFAULT_VALUE;
}

function getEntryPoint(value) {
  return entryTypes[value]?.attribution || entryTypes.OTHER.attribution;
}

function isNotTheSamePPC(newPPC, lastPPC) {
  const allURLParameters = [...storageURLParameters, REFERRER, ENTRY_PAGE];

  return JSON.stringify(newPPC, allURLParameters) !== JSON.stringify(lastPPC, allURLParameters);
}

function getNewPPC(currentPPCs, t, entrypoint) {
  const referrer = getReferrer(urlParams?.get('referrer') || document?.referrer);
  let actualEntryPoint = entrypoint;

  if (!referrer && currentPPCs.length !== 0) {
    return;
  }

  const adType = urlParams?.get('Type') || DEFAULT_VALUE;

  const isFromTheBlog = !!(urlParams?.get('utm_source') && urlParams?.get('utm_source') === 'blog');

  actualEntryPoint = isFromTheBlog || ENTRY_BLOG_PAGE ? (actualEntryPoint = 'BLOG') : entrypoint;

  const idArray = currentPPCs.map((key) => key.id);
  const lastVisitID = currentPPCs[idArray.indexOf(Math.max(...idArray))]?.id || 0;
  const newPPC = {
    id: lastVisitID + 1,
    t,
    [ENTRY_PAGE]: urlParams?.get(ENTRY_PAGE) || getEntryPoint(actualEntryPoint),
    [REFERRER]: referrer,
    ...Object.assign({}, ...storageURLParameters.map((param) => ({ [param]: urlParams?.get(param) || DEFAULT_VALUE }))),
    adType,
  };

  return isNotTheSamePPC(newPPC, currentPPCs[currentPPCs.length - 1]) ? [...currentPPCs, newPPC] : currentPPCs;
}

function setChannelAttribution(t = new Date(), entrypoint = '') {
  try {
    if (!hasLocalStorage) {
      throw new Error('### LocalStorage not available on setCA.');
    }

    if (!(t instanceof Date)) {
      t = new Date();
    }

    const currentPPCs = JSON.parse(storage.getItem('channelAttribution'));

    const newPPC = getNewPPC(currentPPCs || [], t.toISOString().slice(0, 19).replace('T', ' '), entrypoint);

    if (newPPC) {
      storage.setItem('channelAttribution', JSON.stringify(newPPC));

      cookies?.setCookieValue(cookieName, JSON.stringify(newPPC[newPPC.length - 1]), {
        domain: getCookieDomain(),
        expires: 0,
        path: '/',
        sameSite: 'Lax',
        secure: true,
      });
    }
  } catch (e) {
    console.error(`### Unable to set data in local storage on setCA: ${e}`);
  }
}

function getLastChannelAttribution() {
  try {
    if (!hasLocalStorage) {
      throw new Error('### LocalStorage not available on getLastCA.');
    }
    const channelAttribution = JSON.parse(storage.getItem('channelAttribution'));

    if (channelAttribution && channelAttribution.length > 0) {
      return channelAttribution[channelAttribution.length - 1];
    }
  } catch (e) {
    console.error(`### Unable to get data from local storage on getLastCA: ${e}`);
  }
}

function syncChannelAttributionWithCookie() {
  try {
    if (!hasLocalStorage) {
      throw new Error('### LocalStorage not available on SyncCA.');
    }

    const currentPPCs = JSON.parse(storage.getItem('channelAttribution')) || [];
    const lastChannelAttribution = currentPPCs?.length ? currentPPCs[currentPPCs?.length - 1] : null;
    const channelAttributionCookieData = JSON.parse(cookies?.getCookie(cookieName)) || {};

    if (
      channelAttributionCookieData?.id
      && (!lastChannelAttribution?.id || isNotTheSamePPC(channelAttributionCookieData, lastChannelAttribution))
    ) {
      storage.setItem('channelAttribution', JSON.stringify([...currentPPCs, channelAttributionCookieData]));
    }
  } catch (e) {
    console.error(`### Unable to set data in local storage on SyncCA: ${e}`);
  }
}

if (typeof window !== 'undefined') {
  syncChannelAttributionWithCookie();
}

export const ca = {
  setChannelAttribution,
  getLastChannelAttribution,
};
