import { PROPERTY_TYPE_DEFAULT, PROPERTY_TYPES } from '@/config/property-types';

export function findPropertyTypeByKey(propertyType) {
  return PROPERTY_TYPES.find((pType) => pType.key === propertyType?.toLowerCase()) || PROPERTY_TYPE_DEFAULT;
}

export function findPropertyTypeByUrlParam(propertyTypeUrlParam) {
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n;

  return PROPERTY_TYPES.find((pType) => t(pType.slug) === propertyTypeUrlParam?.toLowerCase()) || PROPERTY_TYPE_DEFAULT;
}
