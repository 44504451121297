export const commonValues = {
  HOSTELWORLD: 'Hostelworld.com',
  HOSTELWORLDURL: 'https://www.hostelworld.com/',
  OGIMAGE: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_600/v1647449187/pwa/hostelworld.png',
  CDNRAWURL: 'https://a.hwstatic.com/raw/upload/f_auto,q_auto/wds',
  CDNIMAGESURL: 'https://a.hwstatic.com/image/upload/f_auto,q_auto/wds',
  TRANSPARENTPIXEL:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  HOSTELCHAINURL: 'hostel-group',
  // @TODO: Change to final PWA URL destination
  HOSTELWORLD_LOGIN_URL: 'pwa/login',
  HOSTELWORLDCUSTOMERSERVICEMAIL: 'customerservice@hostelworld.com',
  HOSTELWORLDDATAPROTECTIONEMAIL: 'dataprotectionofficer@hostelworld.com',
  BESTPRICEGUARANTEECLAIMFORM:
    'https://hwhelp.hostelworldgroup.com/hc/{LANGUAGECODE}/requests/new?ticket_form_id=195785',
  LANGUAGEMAPPING: {
    french: 'fr',
    german: 'de',
    spanish: 'es',
    italian: 'it',
    portuguese: 'pt',
    brazilian: 'br',
    swedish: 'sv',
    polish: 'pl',
    finnish: 'fi',
    danish: 'da',
    dutch: 'nl',
    norwegian: 'no',
    czech: 'cs',
    russian: 'ru',
    turkish: 'tr',
    chinese: 'zh',
    japanese: 'ja',
    korean: 'ko',
  },
};
