export function GoogleTagManager() {
  const { $sentryCaptureException } = useNuxtApp();
  const { isTrackingAllowed } = checkGdpr();
  const testingStore = useTestingStore();
  const isGoogleTagManagerEnabled = testingStore.hasEnabledFeature('web_googleTagManager');
  const instance = window.dataLayer || [];
  const toReplaceOnPageView = { guesthouse: 'bed and breakfast' };

  const event = (options) => {
    if (typeof options !== 'object') {
      return;
    }

    const {
      events = [],
      ...currentOptions
    } = options;

    instance.push(currentOptions);

    events.forEach((event) => {
      instance.push({ event });
    });
  };

  const checkIfAbleToSendTracking = () => {
    if (!isGoogleTagManagerEnabled) {
      return false;
    }

    if (!isTrackingAllowed) {
      console.log('Tracking not allowed');
      return false;
    }

    if (!window?.dataLayer) {
      console.error('GTM Analytics not present on DOM');
      return false;
    }
    return true;
  };

  return {
    onEvent: (eventName, values = {}) => {
      if (checkIfAbleToSendTracking()) {
        event({
          event: eventName,
          ...values,
        });
      }
    },
    onFormattedEvent: (key, ...values) => {
      if (checkIfAbleToSendTracking()) {
        event({
          event: key,
          ...Object.assign({}, ...values.map((value, i) => ({ [`parameter${i + 1}`]: value }))),
        });
      }
    },
    onPageView: (values) => {
      if (checkIfAbleToSendTracking()) {
        // @TODO STATICPWA-670: This will need to be removed but it's to investigate why sometimes gtmVariables aren't being passed
        if (!values.gtmPageName) {
          $sentryCaptureException(`GTM Variables problem: ${JSON.stringify(values)}`);
        }

        // TODO: Check for a permanent solution
        if (values && toReplaceOnPageView[values?.gtmPropertyType]) {
          values.gtmPropertyType = toReplaceOnPageView[values.gtmPropertyType];
        }
        event({
          events: ['pageview'],
          ...values,
        });
      }
    },
  };
}
