// Values for key are: hostel, hotel, guesthouse, campsite, apartment (to be used on API calls)
// Category comes from here: https://support.google.com/hotelprices/answer/9970971?sjid=13694748219367287423-EU
export const PROPERTY_TYPES = [
  {
    id: 1,
    type: 'HOSTELS',
    key: 'hostel',
    transcode: 't_PROPERTYTYPEHOSTELS',
    transcode_s: 't_PROPERTYTYPEHOSTEL',
    slug: 't_SLUGPROPERTYTYPEHOSTELS',
    category: 'Hostel',
    limit: 30,
  },
  {
    id: 2,
    type: 'HOTELS',
    key: 'hotel',
    transcode: 't_PROPERTYTYPEHOTELS',
    transcode_s: 't_PROPERTYTYPEHOTEL',
    slug: 't_SLUGPROPERTYTYPEHOTELS',
    category: 'Hotel',
    limit: 30,
  },
  {
    id: 3,
    type: 'GUESTHOUSES',
    key: 'guesthouse',
    transcode: 't_PROPERTYTYPEBNBS',
    transcode_s: 't_PROPERTYTYPEBNB',
    slug: 't_SLUGPROPERTYTYPEBNB',
    category: 'Bed and breakfast',
    limit: 30,
  },
];

export const PROPERTY_TYPE_DEFAULT = PROPERTY_TYPES[0];
