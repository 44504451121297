import { useMediaQuery } from '@vueuse/core';

export default defineNuxtPlugin(() => {
  let screenSizes = {};

  if (process.server) {
    // ### SERVER SIDE
    const {
      isMobile,
      isTablet,
      isDesktop,
      isMobileOrTablet,
    } = useDevice();

    screenSizes = {
      isExtraSmallScreen: ref(isMobile),
      isSmallScreen: ref(isTablet),
      isLargeScreen: ref(isDesktop),
      isSmallOrMediumScreen: ref(isMobileOrTablet),
      isBelowMediumScreen: ref(isMobileOrTablet),
    };
  } else {
    // ### CLIENT SIDE
    screenSizes = {
      isExtraSmallScreen: computed(() => useMediaQuery('(max-width: 639px)').value),
      isSmallScreen: computed(() => useMediaQuery('(min-width: 640px) and (max-width: 767px)').value),
      isMediumScreen: computed(() => useMediaQuery('(min-width: 768px) and (max-width: 1023px)')),
      isLargeScreen: computed(() => useMediaQuery('(min-width: 1024px)').value),
      isSmallOrMediumScreen: computed(() => useMediaQuery('(max-width: 1023px)').value),
      isBelowMediumScreen: computed(() => useMediaQuery('(max-width: 767px)').value),
    };
  }

  return { provide: { mq: screenSizes } };
});
