export function useApiIterable() {
  const { $api } = useNuxtApp();

  const sendUsersUpdateEvent = async (body) => {
    let result = ref(null);

    try {
      result = ref(await $api.itapi(body));
    } catch (error) {
      console.error('failed to send a Users Update event to Iterable', error.message);
    }
    return result?.value || null;
  };

  return { sendUsersUpdateEvent };
}
