export const COOKIECONSENTLINKS = {
  COOKIESPOLICY: {
    SPWA: 'st/cookiespolicy',
    STATICWEB: 'cookiespolicy',
  },
  SECURITYPRIVACY: {
    SPWA: 'st/securityprivacy',
    STATICWEB: 'securityprivacy',
  },
  HOSTELTERMSANDCONDITIONS: {
    SPWA: 'st/hosteltermsandconditions',
    STATICWEB: 'hosteltermsandconditions',
  },
  GUARANTEE: {
    SPWA: 'st/guarantee',
    STATICWEB: 'guarantee',
  },
  REVIEWGUIDELINES: {
    SPWA: 'st/reviewguidelines',
    STATICWEB: 'reviewguidelines',
  },
};
