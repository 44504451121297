export const useTestingStore = defineStore('testingStore', () => {
  const enabledFeatures = ref({});

  const getEnabledFeatures = computed(() => {
    return Object.keys(enabledFeatures.value);
  });

  const hasEnabledFeature = (features) => {
    const enabledFeaturesArray = Object.keys(enabledFeatures.value);

    if (Array.isArray(features)) {
      return features.reduce((prevValue, feature) => {
        prevValue[feature] = enabledFeaturesArray?.includes(feature);
        return prevValue;
      }, {});
    }
    return enabledFeaturesArray?.includes(features);
  };

  const getFeatureVariables = (features) => {
    if (Array.isArray(features)) {
      return features.reduce((prevValue, feature) => {
        prevValue[feature] = hasEnabledFeature(feature) ? enabledFeatures.value[feature] : {};
        return prevValue;
      }, {});
    }

    return hasEnabledFeature(features) ? enabledFeatures.value[features] : {};
  };

  const setEnabledFeatures = (newEnabledFeatures) => {
    enabledFeatures.value = newEnabledFeatures;
  };

  return {
    enabledFeatures,
    getEnabledFeatures,
    getFeatureVariables,
    hasEnabledFeature,
    setEnabledFeatures,
  };
});
