export const useSessionStore = defineStore('sessionStore', () => {
  const {
    isTablet,
    isDesktop,
    userAgent,
  } = useDevice();

  const state = ref({
    entryPoint: '',
    device: isTablet ? 'tablet' : isDesktop ? 'desktop' : 'mobile',
    userAgent,
  });

  const getEntryPoint = computed(() => {
    return state?.value?.entryPoint;
  });

  const getEntryPointForTracking = computed(() => {
    switch (getEntryPoint.value) {
      case 'HOMEPAGE':
        return 'homepage';
      case 'HOSTEL':
      case 'HOTEL':
      case 'GUESTHOUSE':
        return 'propertyLanding';
      case 'CONTINENT':
        return 'continentLanding';
      case 'COUNTRY':
        return 'countryLanding';
      case 'AREA':
      case 'REGION':
        return 'regionLanding';
      case 'CITY':
      case 'DISTRICT':
        return 'staticFab';
      case 'PROPERTY':
        return 'staticMicrosite';
      default:
        return 'other';
    }
  });

  const getUserDevice = computed(() => {
    return state?.value?.device;
  });

  const getUserUA = computed(() => {
    return state?.value?.userAgent;
  });

  const setEntryPoint = (value) => {
    state.value.entryPoint = value;
  };

  return {
    getUserUA,
    getUserDevice,
    getEntryPoint,
    getEntryPointForTracking,
    setEntryPoint,
  };
});
