
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr",
  "de",
  "es",
  "it",
  "pt",
  "br",
  "sv",
  "pl",
  "fi",
  "da",
  "nl",
  "no",
  "cs",
  "ru",
  "tr",
  "zh",
  "ja",
  "ko"
]

export const localeLoaders = {
  "en": [{ key: "../src/locales/en_GB.json", load: () => import("../src/locales/en_GB.json" /* webpackChunkName: "locale__build_src_locales_en_GB_json" */), cache: true }],
  "fr": [{ key: "../src/locales/fr_FR.json", load: () => import("../src/locales/fr_FR.json" /* webpackChunkName: "locale__build_src_locales_fr_FR_json" */), cache: true }],
  "de": [{ key: "../src/locales/de_DE.json", load: () => import("../src/locales/de_DE.json" /* webpackChunkName: "locale__build_src_locales_de_DE_json" */), cache: true }],
  "es": [{ key: "../src/locales/es_ES.json", load: () => import("../src/locales/es_ES.json" /* webpackChunkName: "locale__build_src_locales_es_ES_json" */), cache: true }],
  "it": [{ key: "../src/locales/it_IT.json", load: () => import("../src/locales/it_IT.json" /* webpackChunkName: "locale__build_src_locales_it_IT_json" */), cache: true }],
  "pt": [{ key: "../src/locales/pt_PT.json", load: () => import("../src/locales/pt_PT.json" /* webpackChunkName: "locale__build_src_locales_pt_PT_json" */), cache: true }],
  "br": [{ key: "../src/locales/pt_BR.json", load: () => import("../src/locales/pt_BR.json" /* webpackChunkName: "locale__build_src_locales_pt_BR_json" */), cache: true }],
  "sv": [{ key: "../src/locales/sv_SE.json", load: () => import("../src/locales/sv_SE.json" /* webpackChunkName: "locale__build_src_locales_sv_SE_json" */), cache: true }],
  "pl": [{ key: "../src/locales/pl_PL.json", load: () => import("../src/locales/pl_PL.json" /* webpackChunkName: "locale__build_src_locales_pl_PL_json" */), cache: true }],
  "fi": [{ key: "../src/locales/fi_FI.json", load: () => import("../src/locales/fi_FI.json" /* webpackChunkName: "locale__build_src_locales_fi_FI_json" */), cache: true }],
  "da": [{ key: "../src/locales/da_DK.json", load: () => import("../src/locales/da_DK.json" /* webpackChunkName: "locale__build_src_locales_da_DK_json" */), cache: true }],
  "nl": [{ key: "../src/locales/nl_NL.json", load: () => import("../src/locales/nl_NL.json" /* webpackChunkName: "locale__build_src_locales_nl_NL_json" */), cache: true }],
  "no": [{ key: "../src/locales/nb_NO.json", load: () => import("../src/locales/nb_NO.json" /* webpackChunkName: "locale__build_src_locales_nb_NO_json" */), cache: true }],
  "cs": [{ key: "../src/locales/cs_CZ.json", load: () => import("../src/locales/cs_CZ.json" /* webpackChunkName: "locale__build_src_locales_cs_CZ_json" */), cache: true }],
  "ru": [{ key: "../src/locales/ru_RU.json", load: () => import("../src/locales/ru_RU.json" /* webpackChunkName: "locale__build_src_locales_ru_RU_json" */), cache: true }],
  "tr": [{ key: "../src/locales/tr_TR.json", load: () => import("../src/locales/tr_TR.json" /* webpackChunkName: "locale__build_src_locales_tr_TR_json" */), cache: true }],
  "zh": [{ key: "../src/locales/zh_CN.json", load: () => import("../src/locales/zh_CN.json" /* webpackChunkName: "locale__build_src_locales_zh_CN_json" */), cache: true }],
  "ja": [{ key: "../src/locales/ja_JP.json", load: () => import("../src/locales/ja_JP.json" /* webpackChunkName: "locale__build_src_locales_ja_JP_json" */), cache: true }],
  "ko": [{ key: "../src/locales/ko_KR.json", load: () => import("../src/locales/ko_KR.json" /* webpackChunkName: "locale__build_src_locales_ko_KR_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/config/i18n.config.ts?hash=75ed3d17&config=1" /* webpackChunkName: "__src_config_i18n_config_ts_75ed3d17" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./src/config/i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "language": "en-GB",
      "isoUnder": "en_GB",
      "tag": "en",
      "lang": "en",
      "url": "en",
      "baseUrl": "BASE_URL_EN",
      "rwdUrl": "PWA_URL_EN",
      "name": "English",
      "enName": "English",
      "isCatchallLocale": true,
      "files": [
        "/build/src/locales/en_GB.json"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "isoUnder": "fr_FR",
      "tag": "fr",
      "lang": "fr",
      "url": "fr",
      "baseUrl": "BASE_URL_FR",
      "rwdUrl": "PWA_URL_FR",
      "name": "Français",
      "enName": "French",
      "files": [
        "/build/src/locales/fr_FR.json"
      ]
    },
    {
      "code": "de",
      "language": "de-DE",
      "isoUnder": "de_DE",
      "tag": "de",
      "lang": "de",
      "url": "de",
      "baseUrl": "BASE_URL_DE",
      "rwdUrl": "PWA_URL_DE",
      "name": "Deutsch",
      "enName": "German",
      "files": [
        "/build/src/locales/de_DE.json"
      ]
    },
    {
      "code": "es",
      "language": "es-ES",
      "isoUnder": "es_ES",
      "tag": "es",
      "lang": "es",
      "url": "es",
      "baseUrl": "BASE_URL_ES",
      "rwdUrl": "PWA_URL_ES",
      "name": "Español",
      "enName": "Spanish",
      "files": [
        "/build/src/locales/es_ES.json"
      ]
    },
    {
      "code": "it",
      "language": "it-IT",
      "isoUnder": "it_IT",
      "tag": "it",
      "lang": "it",
      "url": "it",
      "baseUrl": "BASE_URL_IT",
      "rwdUrl": "PWA_URL_IT",
      "name": "Italiano",
      "enName": "Italian",
      "files": [
        "/build/src/locales/it_IT.json"
      ]
    },
    {
      "code": "pt",
      "language": "pt-PT",
      "isoUnder": "pt_PT",
      "tag": "pt-PT",
      "lang": "pt",
      "url": "pt",
      "baseUrl": "BASE_URL_PT",
      "rwdUrl": "PWA_URL_PT",
      "name": "Português",
      "enName": "Portuguese",
      "files": [
        "/build/src/locales/pt_PT.json"
      ]
    },
    {
      "code": "br",
      "language": "pt-BR",
      "isoUnder": "pt_BR",
      "tag": "pt-BR",
      "lang": "br",
      "url": "br",
      "baseUrl": "BASE_URL_BR",
      "rwdUrl": "PWA_URL_BR",
      "name": "Português (Brasil)",
      "enName": "Brazilian Portuguese",
      "files": [
        "/build/src/locales/pt_BR.json"
      ]
    },
    {
      "code": "sv",
      "language": "sv-SE",
      "isoUnder": "sv_SE",
      "tag": "sv",
      "lang": "sv",
      "url": "sv",
      "baseUrl": "BASE_URL_SV",
      "rwdUrl": "PWA_URL_SV",
      "name": "Svenska",
      "enName": "Swedish",
      "files": [
        "/build/src/locales/sv_SE.json"
      ]
    },
    {
      "code": "pl",
      "language": "pl-PL",
      "isoUnder": "pl_PL",
      "tag": "pl",
      "lang": "pl",
      "url": "pl",
      "baseUrl": "BASE_URL_PL",
      "rwdUrl": "PWA_URL_PL",
      "name": "Polski",
      "enName": "Polish",
      "files": [
        "/build/src/locales/pl_PL.json"
      ]
    },
    {
      "code": "fi",
      "language": "fi-FI",
      "isoUnder": "fi_FI",
      "tag": "fi",
      "lang": "fi",
      "url": "fi",
      "baseUrl": "BASE_URL_FI",
      "rwdUrl": "PWA_URL_FI",
      "name": "Suomi",
      "enName": "Finnish",
      "files": [
        "/build/src/locales/fi_FI.json"
      ]
    },
    {
      "code": "da",
      "language": "da-DK",
      "isoUnder": "da_DK",
      "tag": "da",
      "lang": "da",
      "url": "da",
      "baseUrl": "BASE_URL_DA",
      "rwdUrl": "PWA_URL_DA",
      "name": "Dansk",
      "enName": "Danish",
      "files": [
        "/build/src/locales/da_DK.json"
      ]
    },
    {
      "code": "nl",
      "language": "nl-NL",
      "isoUnder": "nl_NL",
      "tag": "nl",
      "lang": "nl",
      "url": "nl",
      "baseUrl": "BASE_URL_NL",
      "rwdUrl": "PWA_URL_NL",
      "name": "Nederlands",
      "enName": "Dutch",
      "files": [
        "/build/src/locales/nl_NL.json"
      ]
    },
    {
      "code": "no",
      "language": "nb-NO",
      "isoUnder": "nb_NO",
      "tag": "no",
      "lang": "nb",
      "url": "no",
      "baseUrl": "BASE_URL_NO",
      "rwdUrl": "PWA_URL_NO",
      "name": "Norsk",
      "enName": "Norwegian",
      "files": [
        "/build/src/locales/nb_NO.json"
      ]
    },
    {
      "code": "cs",
      "language": "cs-CZ",
      "isoUnder": "cs_CZ",
      "tag": "cs",
      "lang": "cs",
      "url": "cs",
      "baseUrl": "BASE_URL_CS",
      "rwdUrl": "PWA_URL_CS",
      "name": "český",
      "enName": "Czech",
      "files": [
        "/build/src/locales/cs_CZ.json"
      ]
    },
    {
      "code": "ru",
      "language": "ru-RU",
      "isoUnder": "ru_RU",
      "tag": "ru",
      "lang": "ru",
      "url": "ru",
      "baseUrl": "BASE_URL_RU",
      "rwdUrl": "PWA_URL_RU",
      "name": "Русский",
      "enName": "Russian",
      "files": [
        "/build/src/locales/ru_RU.json"
      ]
    },
    {
      "code": "tr",
      "language": "tr-TR",
      "isoUnder": "tr_TR",
      "tag": "tr",
      "lang": "tr",
      "url": "tr",
      "baseUrl": "BASE_URL_TR",
      "rwdUrl": "PWA_URL_TR",
      "name": "Türk",
      "enName": "Turkish",
      "files": [
        "/build/src/locales/tr_TR.json"
      ]
    },
    {
      "code": "zh",
      "language": "zh-CN",
      "isoUnder": "zh_CN",
      "tag": "zh",
      "lang": "zh",
      "url": "zh",
      "baseUrl": "BASE_URL_ZH",
      "rwdUrl": "PWA_URL_ZH",
      "name": "中文",
      "enName": "Chinese",
      "files": [
        "/build/src/locales/zh_CN.json"
      ]
    },
    {
      "code": "ja",
      "language": "ja-JP",
      "isoUnder": "ja_JP",
      "tag": "ja",
      "lang": "ja",
      "url": "ja",
      "baseUrl": "BASE_URL_JA",
      "rwdUrl": "PWA_URL_JA",
      "name": "日本語",
      "enName": "Japanese",
      "files": [
        "/build/src/locales/ja_JP.json"
      ]
    },
    {
      "code": "ko",
      "language": "ko-KR",
      "isoUnder": "ko_KR",
      "tag": "ko",
      "lang": "ko",
      "url": "ko",
      "baseUrl": "BASE_URL_KO",
      "rwdUrl": "PWA_URL_KO",
      "name": "한국어",
      "enName": "Korean",
      "files": [
        "/build/src/locales/ko_KR.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "legacy": false,
  "locale": "en",
  "fallbackLocale": "en",
  "precompile": {
    "strictMessage": false
  },
  "defaultLocaleObj": {
    "code": "en",
    "language": "en-GB",
    "isoUnder": "en_GB",
    "file": "en_GB.json",
    "tag": "en",
    "lang": "en",
    "url": "en",
    "baseUrl": "BASE_URL_EN",
    "rwdUrl": "PWA_URL_EN",
    "name": "English",
    "enName": "English",
    "isCatchallLocale": true
  },
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-GB",
    "isoUnder": "en_GB",
    "tag": "en",
    "lang": "en",
    "url": "en",
    "baseUrl": "BASE_URL_EN",
    "rwdUrl": "PWA_URL_EN",
    "name": "English",
    "enName": "English",
    "isCatchallLocale": true,
    "files": [
      {
        "path": "/build/src/locales/en_GB.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "isoUnder": "fr_FR",
    "tag": "fr",
    "lang": "fr",
    "url": "fr",
    "baseUrl": "BASE_URL_FR",
    "rwdUrl": "PWA_URL_FR",
    "name": "Français",
    "enName": "French",
    "files": [
      {
        "path": "/build/src/locales/fr_FR.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de-DE",
    "isoUnder": "de_DE",
    "tag": "de",
    "lang": "de",
    "url": "de",
    "baseUrl": "BASE_URL_DE",
    "rwdUrl": "PWA_URL_DE",
    "name": "Deutsch",
    "enName": "German",
    "files": [
      {
        "path": "/build/src/locales/de_DE.json"
      }
    ]
  },
  {
    "code": "es",
    "language": "es-ES",
    "isoUnder": "es_ES",
    "tag": "es",
    "lang": "es",
    "url": "es",
    "baseUrl": "BASE_URL_ES",
    "rwdUrl": "PWA_URL_ES",
    "name": "Español",
    "enName": "Spanish",
    "files": [
      {
        "path": "/build/src/locales/es_ES.json"
      }
    ]
  },
  {
    "code": "it",
    "language": "it-IT",
    "isoUnder": "it_IT",
    "tag": "it",
    "lang": "it",
    "url": "it",
    "baseUrl": "BASE_URL_IT",
    "rwdUrl": "PWA_URL_IT",
    "name": "Italiano",
    "enName": "Italian",
    "files": [
      {
        "path": "/build/src/locales/it_IT.json"
      }
    ]
  },
  {
    "code": "pt",
    "language": "pt-PT",
    "isoUnder": "pt_PT",
    "tag": "pt-PT",
    "lang": "pt",
    "url": "pt",
    "baseUrl": "BASE_URL_PT",
    "rwdUrl": "PWA_URL_PT",
    "name": "Português",
    "enName": "Portuguese",
    "files": [
      {
        "path": "/build/src/locales/pt_PT.json"
      }
    ]
  },
  {
    "code": "br",
    "language": "pt-BR",
    "isoUnder": "pt_BR",
    "tag": "pt-BR",
    "lang": "br",
    "url": "br",
    "baseUrl": "BASE_URL_BR",
    "rwdUrl": "PWA_URL_BR",
    "name": "Português (Brasil)",
    "enName": "Brazilian Portuguese",
    "files": [
      {
        "path": "/build/src/locales/pt_BR.json"
      }
    ]
  },
  {
    "code": "sv",
    "language": "sv-SE",
    "isoUnder": "sv_SE",
    "tag": "sv",
    "lang": "sv",
    "url": "sv",
    "baseUrl": "BASE_URL_SV",
    "rwdUrl": "PWA_URL_SV",
    "name": "Svenska",
    "enName": "Swedish",
    "files": [
      {
        "path": "/build/src/locales/sv_SE.json"
      }
    ]
  },
  {
    "code": "pl",
    "language": "pl-PL",
    "isoUnder": "pl_PL",
    "tag": "pl",
    "lang": "pl",
    "url": "pl",
    "baseUrl": "BASE_URL_PL",
    "rwdUrl": "PWA_URL_PL",
    "name": "Polski",
    "enName": "Polish",
    "files": [
      {
        "path": "/build/src/locales/pl_PL.json"
      }
    ]
  },
  {
    "code": "fi",
    "language": "fi-FI",
    "isoUnder": "fi_FI",
    "tag": "fi",
    "lang": "fi",
    "url": "fi",
    "baseUrl": "BASE_URL_FI",
    "rwdUrl": "PWA_URL_FI",
    "name": "Suomi",
    "enName": "Finnish",
    "files": [
      {
        "path": "/build/src/locales/fi_FI.json"
      }
    ]
  },
  {
    "code": "da",
    "language": "da-DK",
    "isoUnder": "da_DK",
    "tag": "da",
    "lang": "da",
    "url": "da",
    "baseUrl": "BASE_URL_DA",
    "rwdUrl": "PWA_URL_DA",
    "name": "Dansk",
    "enName": "Danish",
    "files": [
      {
        "path": "/build/src/locales/da_DK.json"
      }
    ]
  },
  {
    "code": "nl",
    "language": "nl-NL",
    "isoUnder": "nl_NL",
    "tag": "nl",
    "lang": "nl",
    "url": "nl",
    "baseUrl": "BASE_URL_NL",
    "rwdUrl": "PWA_URL_NL",
    "name": "Nederlands",
    "enName": "Dutch",
    "files": [
      {
        "path": "/build/src/locales/nl_NL.json"
      }
    ]
  },
  {
    "code": "no",
    "language": "nb-NO",
    "isoUnder": "nb_NO",
    "tag": "no",
    "lang": "nb",
    "url": "no",
    "baseUrl": "BASE_URL_NO",
    "rwdUrl": "PWA_URL_NO",
    "name": "Norsk",
    "enName": "Norwegian",
    "files": [
      {
        "path": "/build/src/locales/nb_NO.json"
      }
    ]
  },
  {
    "code": "cs",
    "language": "cs-CZ",
    "isoUnder": "cs_CZ",
    "tag": "cs",
    "lang": "cs",
    "url": "cs",
    "baseUrl": "BASE_URL_CS",
    "rwdUrl": "PWA_URL_CS",
    "name": "český",
    "enName": "Czech",
    "files": [
      {
        "path": "/build/src/locales/cs_CZ.json"
      }
    ]
  },
  {
    "code": "ru",
    "language": "ru-RU",
    "isoUnder": "ru_RU",
    "tag": "ru",
    "lang": "ru",
    "url": "ru",
    "baseUrl": "BASE_URL_RU",
    "rwdUrl": "PWA_URL_RU",
    "name": "Русский",
    "enName": "Russian",
    "files": [
      {
        "path": "/build/src/locales/ru_RU.json"
      }
    ]
  },
  {
    "code": "tr",
    "language": "tr-TR",
    "isoUnder": "tr_TR",
    "tag": "tr",
    "lang": "tr",
    "url": "tr",
    "baseUrl": "BASE_URL_TR",
    "rwdUrl": "PWA_URL_TR",
    "name": "Türk",
    "enName": "Turkish",
    "files": [
      {
        "path": "/build/src/locales/tr_TR.json"
      }
    ]
  },
  {
    "code": "zh",
    "language": "zh-CN",
    "isoUnder": "zh_CN",
    "tag": "zh",
    "lang": "zh",
    "url": "zh",
    "baseUrl": "BASE_URL_ZH",
    "rwdUrl": "PWA_URL_ZH",
    "name": "中文",
    "enName": "Chinese",
    "files": [
      {
        "path": "/build/src/locales/zh_CN.json"
      }
    ]
  },
  {
    "code": "ja",
    "language": "ja-JP",
    "isoUnder": "ja_JP",
    "tag": "ja",
    "lang": "ja",
    "url": "ja",
    "baseUrl": "BASE_URL_JA",
    "rwdUrl": "PWA_URL_JA",
    "name": "日本語",
    "enName": "Japanese",
    "files": [
      {
        "path": "/build/src/locales/ja_JP.json"
      }
    ]
  },
  {
    "code": "ko",
    "language": "ko-KR",
    "isoUnder": "ko_KR",
    "tag": "ko",
    "lang": "ko",
    "url": "ko",
    "baseUrl": "BASE_URL_KO",
    "rwdUrl": "PWA_URL_KO",
    "name": "한국어",
    "enName": "Korean",
    "files": [
      {
        "path": "/build/src/locales/ko_KR.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
