<script setup>
import { WdsSetup } from '@wds/components';

const testingStore = useTestingStore();

const isCriticalSvgInlineEnabled = testingStore.hasEnabledFeature('web_criticalSvgInline');
</script>

<template>
  <template v-if="!isCriticalSvgInlineEnabled">
    <WdsSetup />
  </template>

  <template v-else-if="isCriticalSvgInlineEnabled">
    <div class="svg-inline">
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol
          id="arrow-left"
          viewBox="0 0 16 16"
        >
          <path d="M1.45 7.49l6.5-7 1.1 1.02-5.33 5.74H15v1.5H3.72l5.33 5.74-1.1 1.02-6.5-7a.75.75 0 010-1.02z" />
        </symbol>
        <symbol
          id="arrow-right"
          viewBox="0 0 16 16"
        >
          <path d="M14.55 7.49l-6.5-7-1.1 1.02 5.33 5.74H1v1.5h11.28l-5.33 5.74 1.1 1.02 6.5-7a.75.75 0 000-1.02z" />
        </symbol>
        <symbol
          id="breakfast"
          viewBox="0 0 16 16"
        >
          <path
            d="M5.25 0c0 .851-.197 1.23-.401 1.589-.243.425-.599.953-.599 1.911h1.5c0-.446.186-.792.401-1.167.296-.517.599-1.153.599-2.333h-1.5zM8.849 1.589C9.053 1.231 9.25.851 9.25 0h1.5c0 1.18-.303 1.816-.599 2.333-.215.375-.401.721-.401 1.167h-1.5c0-.958.356-1.486.599-1.911zM11.943 14.002c-3.917.3-5.945.312-9.884 0l-.118 1.496c4.02.318 6.127.305 10.116 0l-.114-1.496z"
          />
          <path
            fill-rule="evenodd"
            d="M1.25 5.5A.75.75 0 012 4.75h11a2.75 2.75 0 110 5.5h-.326c-.357 1.711-1.924 2.762-3.57 2.993-1.447.203-2.612.2-4.197.001C3.009 13.007 1.25 11.646 1.25 9.5v-4zm11.5 3.25H13a1.25 1.25 0 100-2.5h-.25v2.5zm-10-2.5V9.5c0 1.167.952 2.082 2.343 2.256 1.47.183 2.502.184 3.803.001 1.407-.197 2.354-1.097 2.354-2.257V6.25h-8.5z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="calendar-check-in"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.625 11a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM8.75 10a1 1 0 100-2 1 1 0 000 2zM13 9a1 1 0 11-2 0 1 1 0 012 0z"
          />
          <path
            fill-rule="evenodd"
            d="M4.75 2.296c2.006-.047 4.497-.061 6.5-.028V0h1.5v2.307c.522.019.976.044 1.328.075.974.087 1.383.406 1.495 1.392.123 1.085.177 3.195.177 5.304 0 1.407-.026 2.815-.077 3.894a20.827 20.827 0 01-.1 1.355c-.118.995-.565 1.245-1.507 1.307-1.2.08-3.643.116-6.09.116-2.448 0-4.89-.037-6.09-.116C.937 15.57.49 15.316.383 14.313.27 13.252.24 11.14.253 9.023c.012-2.12.07-4.234.162-5.27.087-.974.463-1.265 1.424-1.338.361-.028.846-.053 1.41-.074V0h1.5v2.296zm-1.5 1.546V5h1.5V3.797c1.998-.047 4.512-.062 6.5-.029V5h1.5V3.808c.242.009 1.314.089 1.314.089s.186 4.287.186 5.18a85.04 85.04 0 01-.076 3.823c-.024.512-.054.932-.088 1.229-1.735.13-3.486.126-5.228.122l-.882-.001-.87.001c-1.744.004-3.497.008-5.234-.122-.094-.915-.132-3.01-.12-5.098.013-2.084.074-4.176.155-5.117.447-.035.895-.055 1.343-.072z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="calendar-check-out"
          viewBox="0 0 16 16"
        >
          <path
            d="M13.5 9.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM4 10a1 1 0 100-2 1 1 0 000 2zM7.5 10a1 1 0 100-2 1 1 0 000 2z"
          />
          <path
            fill-rule="evenodd"
            d="M4.75 2.296c2.006-.047 4.497-.061 6.5-.028V0h1.5v2.307c.522.019.976.044 1.328.075.974.087 1.383.406 1.495 1.392.123 1.085.177 3.195.177 5.304 0 1.407-.026 2.815-.077 3.894a20.827 20.827 0 01-.1 1.355c-.118.995-.565 1.245-1.507 1.307-1.2.08-3.643.116-6.09.116-2.448 0-4.89-.037-6.09-.116C.937 15.57.49 15.316.383 14.313.27 13.252.24 11.14.253 9.023c.012-2.12.07-4.234.162-5.27.087-.974.463-1.265 1.424-1.338.361-.028.846-.053 1.41-.074V0h1.5v2.296zm-1.5 1.546V5h1.5V3.797c1.998-.047 4.512-.062 6.5-.029V5h1.5V3.808c.242.009 1.314.089 1.314.089s.186 4.287.186 5.18a85.04 85.04 0 01-.076 3.823c-.024.512-.054.932-.088 1.229-1.735.13-3.486.126-5.228.122l-.882-.001-.87.001c-1.744.004-3.497.008-5.234-.122-.094-.915-.132-3.01-.12-5.098.013-2.084.074-4.176.155-5.117.447-.035.895-.055 1.343-.072z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="calendar"
          viewBox="0 0 16 16"
        >
          <path d="M5 9a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 11-2 0 1 1 0 012 0zM12 10a1 1 0 100-2 1 1 0 000 2z" />
          <path
            fill-rule="evenodd"
            d="M4.75 2.296c2.006-.047 4.497-.061 6.5-.028V0h1.5v2.307c.522.019.976.044 1.328.075.974.087 1.383.406 1.495 1.392.123 1.085.177 3.195.177 5.304 0 1.407-.026 2.815-.077 3.894a20.827 20.827 0 01-.1 1.355c-.118.995-.565 1.245-1.507 1.307-1.2.08-3.643.116-6.09.116-2.448 0-4.89-.037-6.09-.116C.937 15.57.49 15.316.383 14.313.27 13.252.24 11.14.253 9.023c.012-2.12.07-4.234.162-5.27.087-.974.463-1.265 1.424-1.338.361-.028.846-.053 1.41-.074V0h1.5v2.296zm-1.5 1.546V5h1.5V3.797c1.998-.047 4.512-.062 6.5-.029V5h1.5V3.808c.242.009 1.314.089 1.314.089s.186 4.287.186 5.18a85.04 85.04 0 01-.076 3.823c-.024.512-.054.932-.088 1.229-1.735.13-3.486.126-5.228.122l-.882-.001-.87.001c-1.744.004-3.497.008-5.234-.122-.094-.915-.132-3.01-.12-5.098.013-2.084.074-4.176.155-5.117.447-.035.895-.055 1.343-.072z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="caret-down"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.326 10.625a.981.981 0 001.36.005c1.537-1.464 2.513-2.545 3.721-4.145.3-.398.124-.966-.361-1.08-2.308-.538-5.75-.54-8.066-.005-.493.113-.666.694-.353 1.092 1.279 1.63 2.249 2.726 3.699 4.133z"
          />
        </symbol>
        <symbol
          id="caret-up"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.326 5.647a.981.981 0 011.36-.006c1.537 1.465 2.513 2.546 3.721 4.146.3.398.124.966-.361 1.08-2.308.538-5.75.54-8.066.005-.493-.114-.666-.694-.353-1.092 1.279-1.63 2.249-2.726 3.699-4.133z"
          />
        </symbol>
        <symbol
          id="check-in"
          viewBox="0 0 16 16"
        >
          <path
            d="M13.826 1.5H6.322c-.206 0-.322.153-.322.28v2.577H4.5V1.781C4.5.77 5.344 0 6.322 0h7.504c.92 0 1.65.767 1.81 1.646.285 1.56.38 4.191.362 6.653-.02 2.46-.154 4.87-.363 5.988a2.003 2.003 0 01-1.713 1.636c-.207.027-.775.043-1.444.055-1.742.03-4.571.034-5.742-.015-.596-.025-1.16-.155-1.63-.554-.327-.28-.606-.705-.606-1.26v-2.577H6v2.577c0 .287.51.305.752.314l.047.001c1.292.054 5.152.041 6.522-.007.48 0 .712-.008.846-.477.179-.99.312-3.257.33-5.692.02-2.437-.078-4.924-.33-6.337a6.313 6.313 0 01-.013-.046c-.052-.182-.115-.405-.328-.405z"
          />
          <path
            d="M9.604 3.555l3 4.07a.75.75 0 010 .89l-2.896 3.93-1.208-.89 2.068-2.805H1v-1.5h9.464L8.396 4.445l1.208-.89z"
          />
        </symbol>
        <symbol
          id="check-out"
          viewBox="0 0 16 16"
        >
          <path
            d="M15.604 7.626l-3-4.071-1.208.89 2.067 2.805H5v1.5h8.568L11.5 11.555l1.208.89 2.896-3.93a.75.75 0 000-.89z"
          />
          <path
            d="M8.678 1.5H2.174c-.231 0-.286.263-.34.45-.253 1.414-.35 3.9-.332 6.338.019 2.435.152 4.703.331 5.692.134.47.367.477.846.477 1.37.048 4.23.061 5.522.007.215-.009.8-.008.799-.315v-2.577h1.5v2.577c0 .556-.279.98-.606 1.26-.47.399-1.034.53-1.63.554-1.171.049-3 .046-4.742.015-.669-.012-1.237-.028-1.444-.055a2.003 2.003 0 01-1.713-1.636C.155 13.169.02 10.758.002 8.299-.017 5.837.08 3.205.364 1.646.524.767 1.254 0 2.174 0h6.504C9.656 0 10.5.77 10.5 1.78v2.577H9V1.781c0-.128-.116-.281-.322-.281z"
          />
        </symbol>
        <symbol
          id="chevron-down"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8.535 12.53l6.995-7-1.06-1.06-6.466 6.47L1.53 4.47.47 5.53l7.004 7a.772.772 0 001.06 0z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M5.06 8.004l6.47-6.474L10.47.47l-7 7.004a.772.772 0 000 1.06l7 6.996 1.06-1.06-6.47-6.466z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M12.53 7.465L5.53.47 4.47 1.53l6.47 6.466-6.47 6.474 1.06 1.06 7-7.004a.772.772 0 000-1.06z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="chevron-up"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8.535 3.47l6.995 7-1.06 1.06-6.466-6.47-6.474 6.47-1.06-1.06 7.004-7a.772.772 0 011.06 0z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="city"
          viewBox="0 0 16 16"
        >
          <path d="M11.5 10V8H10v2h1.5zM5.5 6V4H4v2h1.5zM5.5 8v2H4V8h1.5z" />
          <path
            fill-rule="evenodd"
            d="M8.5 2.68L4.75.089 1 2.681v11.252l-.87.137.24 1.48c6.037-.974 9.351-.998 15.255 0l.25-1.479a81.4 81.4 0 00-1.375-.22V5.397l-3.75-1.184-2.25.71V2.681zM13 13.642V6.497l-2.25-.71-2.25.71v6.816c.497.006.995.023 1.5.05V12h1.5v1.476c.487.045.986.1 1.5.165zm-6-.313v-9.86L4.75 1.911 2.5 3.467V13.72a57.179 57.179 0 011.5-.18V12h1.5v1.406a37.594 37.594 0 011.5-.078z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="clock"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 1.75a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5zM.25 8a7.75 7.75 0 1115.5 0A7.75 7.75 0 01.25 8z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M7.584 8.624A.75.75 0 017.25 8V3h1.5v4.599l2.666 1.777-.832 1.248-3-2z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="close"
          viewBox="0 0 16 16"
        >
          <path
            d="M9.06 8l5.47-5.47-1.06-1.06L8 6.94 2.53 1.47 1.47 2.53 6.94 8l-5.47 5.47 1.06 1.06L8 9.06l5.47 5.47 1.06-1.06L9.06 8z"
          />
        </symbol>
        <symbol
          id="confirmation"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 1.75a6.25 6.25 0 106.106 4.91l1.466-.32a7.75 7.75 0 11-3.696-5.053l-.752 1.299A6.218 6.218 0 008 1.75z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M7.5 8.94l6.97-6.97 1.06 1.06-7.5 7.5a.75.75 0 01-1.06 0l-3.5-3.5 1.06-1.06L7.5 8.94z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="copy"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.927 3.565c-.037-.62-.093-1.239-.178-1.691-.138-.738-.544-1.319-1.06-1.516C9.752 0 6 0 6 0S2.248 0 1.312.358C.795.555.389 1.136.25 1.874 0 3.21 0 6 0 6s0 2.79.25 4.126c.139.738.545 1.319 1.062 1.516.404.155 1.334.242 2.26.292.038.621.094 1.24.179 1.692.138.738.544 1.318 1.06 1.516.936.358 4.689.358 4.689.358s3.752 0 4.688-.358c.517-.198.923-.778 1.061-1.516.251-1.337.251-4.126.251-4.126s0-2.79-.25-4.126c-.139-.738-.545-1.319-1.062-1.516-.404-.155-1.334-.243-2.26-.293zm-1.506-.054a12.482 12.482 0 00-.146-1.36c-.042-.227-.091-.393-.32-.434-1.25-.223-2.536-.208-3.802-.216-1.367 0-2.759-.025-4.108.216-.229.04-.278.207-.32.433-.242 1.292-.228 2.628-.224 3.938.003 1.244-.006 2.538.224 3.762.09.48.454.464.87.507.28.03.594.053.918.072-.013-.548-.013-.93-.013-.93s0-2.789.25-4.125c.139-.738.545-1.319 1.062-1.516C5.747 3.5 9.5 3.5 9.5 3.5s.377 0 .921.011zm3.354 2.14c-.09-.481-.453-.465-.87-.508-1.095-.114-2.746-.14-3.252-.143-1.187-.005-2.377.02-3.558.143-.417.043-.78.027-.87.507C4.983 6.94 5 8.304 5 9.613c.008 1.236-.003 2.52.225 3.737.09.48.454.463.87.507 1.14.119 2.297.144 3.443.143 1.121-.006 2.25-.027 3.367-.143.417-.044.78-.028.87-.508.102-.545.163-1.473.195-2.342.02-.54.032-1.08.03-1.62-.008-1.245.005-2.509-.225-3.737z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="credit-card"
          viewBox="0 0 16 16"
        >
          <path d="M3 10.75h3v-1.5H3v1.5z" />
          <path
            fill-rule="evenodd"
            d="M14.023 2.25C9.316 2 6.679 2 1.977 2.25A1.75 1.75 0 00.25 4v8a1.75 1.75 0 001.728 1.75c4.59.242 7.357.263 12.046 0A1.75 1.75 0 0015.75 12V4a1.75 1.75 0 00-1.727-1.75zM13.96 3.75c-4.67-.25-7.255-.248-11.92 0-.148.008-.29.08-.29.251v1.75h12.5V4c0-.171-.142-.243-.29-.251zM1.75 12V7.25h12.5V12c0 .173-.143.243-.292.251-4.645.262-7.36.241-11.918 0-.149-.008-.29-.08-.29-.251z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="filters"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M13 8.75H3v-1.5h10v1.5zM16 3.75H0v-1.5h16v1.5zM10 13.75H6v-1.5h4v1.5z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="globe"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M7.969.256c4.276-.164 7.94 2.989 7.776 7.77-.08 2.305-.84 4.284-2.207 5.672-1.374 1.395-3.299 2.127-5.567 2.046-2.24-.08-4.178-.843-5.562-2.206C1.022 12.172.25 10.26.25 8 .25 3.373 3.755.418 7.969.256zm3.728 2.485a5.94 5.94 0 00-1.954-.83c.027.076.05.154.07.233.329 1.376-.4 2.707-1.718 3.118-.298.093-.607.164-.918.234-.653.15-1.311.3-1.873.662-1.153.743.037 1.33.77 1.687l.15.072c.43.209.932.452 1.328.733 1.023.728 1.26 1.99.417 2.961-.173.198-.371.372-.57.545-.336.294-.67.587-.874 1a2.63 2.63 0 00-.243.848c.54.136 1.122.219 1.742.24 1.74.063 3.143-.44 4.17-1.339.162-.527-.042-.99-.494-1.549-.137-.168-.293-.332-.452-.497-.416-.433-.844-.88-.951-1.466-.183-1.001.293-1.8 1.124-2.32.454-.283.492-.4-.006-.733-.16-.11-.36-.26-.535-.476-.32-.393-.342-.87-.28-1.237.121-.716.551-1.373 1.097-1.886zm1.139.998c-.202.178-.384.39-.543.639-.203.32-.27.568.067.789.433.283.826.599 1.025 1.097.12.303.156.634.042.962-.176.501-.633.777-1.065 1.032-.587.347-.746.742-.306 1.243.062.071.143.159.232.257.18.196.398.433.578.654.202.25.412.545.577.88.474-.916.758-2.031.803-3.318.059-1.719-.483-3.168-1.41-4.235zM8.353 2.493c-.047-.199-.205-.508-.934-.691C4.188 2.175 1.75 4.509 1.75 8c0 1.907.643 3.417 1.712 4.47.398.391.863.727 1.389.998.074-.366.186-.689.33-.978.294-.595.753-1.054 1.249-1.482.36-.311.797-.748.253-1.135-.38-.27-.803-.47-1.225-.67-.53-.25-1.059-.5-1.505-.89-1.187-1.035-.661-2.643.538-3.416.67-.432 1.43-.62 2.19-.807.324-.08.65-.16.967-.26.59-.184.858-.699.705-1.337z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="heart-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M1.588 2.433C3.36.523 6.23.523 8 2.433c1.77-1.91 4.641-1.91 6.412 0 1.77 1.911 1.798 4.4 0 6.92C12.444 12.108 8 15 8 15s-4.613-3.127-6.412-5.647c-1.798-2.52-1.77-5.009 0-6.92z"
          />
        </symbol>
        <symbol
          id="heart-line"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 15s-4.613-3.127-6.412-5.647c-1.798-2.52-1.77-5.009 0-6.92C3.192.703 5.698.54 7.472 1.944c.185.146.361.309.528.489.167-.18.343-.343.527-.489 1.774-1.405 4.28-1.242 5.885.49 1.77 1.91 1.798 4.4 0 6.919C12.444 12.108 8 15 8 15zM6.9 3.452C7.906 4.41 8 4.478 8 4.478l1.1-1.026c1.024-.952 3.034-1.27 4.211 0 .654.706.937 1.452.939 2.217.002.788-.294 1.74-1.06 2.812-.852 1.195-2.313 2.499-3.65 3.55a39.09 39.09 0 01-1.527 1.14c-.434-.315-1-.738-1.61-1.223C5.03 10.856 3.576 9.555 2.809 8.48 2.044 7.41 1.748 6.457 1.75 5.67c.002-.765.285-1.51.939-2.216 1.177-1.27 3.204-.958 4.21 0z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="info"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 1.75a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5zM.25 8a7.75 7.75 0 1115.5 0A7.75 7.75 0 01.25 8zM8 4a.75.75 0 01.75.75v.5a.75.75 0 01-1.5 0v-.5A.75.75 0 018 4zm0 3a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 018 7z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="location-pin"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 6.832C2.25 3.52 4.78.75 8 .75s5.75 2.77 5.75 6.082c0 2.483-1.4 4.576-2.697 5.993A15.533 15.533 0 018 15.392a15.505 15.505 0 01-3.053-2.567C3.65 11.408 2.25 9.315 2.25 6.832zM8 13.58c.45-.338 1.201-.952 1.947-1.767 1.203-1.315 2.303-3.056 2.303-4.98 0-2.577-1.947-4.582-4.25-4.582-2.302 0-4.25 2.005-4.25 4.582 0 1.924 1.1 3.665 2.303 4.98A14.636 14.636 0 008 13.58z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M8 5.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM5.25 6.5a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="map"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.9.539a1.75 1.75 0 011.533 0l3.79 1.846a.25.25 0 00.22 0l2.79-1.359a1.75 1.75 0 012.517 1.573v9.503c0 .67-.382 1.28-.984 1.573L11.1 15.461a1.75 1.75 0 01-1.533 0l-3.79-1.846a.25.25 0 00-.22 0l-2.79 1.36A1.75 1.75 0 01.25 13.4V3.898c0-.67.382-1.28.984-1.573L4.9.539zM1.89 3.673l2.86-1.392V12.34l-2.64 1.286a.25.25 0 01-.36-.225V3.898a.25.25 0 01.14-.225zm4.36 8.517V2.118c1.128.55 2.245 1.163 3.4 1.653v10.062c-1.113-.542-2.233-1.23-3.4-1.643zm4.9 1.578l2.96-1.441a.25.25 0 00.14-.225V2.599a.25.25 0 00-.36-.224l-2.74 1.334v10.06z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="menu-line"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 11.25h14v1.5H1v-1.5zM1 7.25h14v1.5H1v-1.5zM1 3.25h14v1.5H1v-1.5z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="minus"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 7.25h14v1.5H1v-1.5z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="money"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M16 12.077c0 .452-.178.97-.633 1.3-1.062.774-2.624 1.134-4.16 1.18-1.55.045-3.208-.226-4.523-.844a6.758 6.758 0 00-2.991-.641 6.137 6.137 0 00-.928.084 3.997 3.997 0 00-.297.062l-.01.002h.002l-.21-.72-.21-.72h.002l.003-.002.01-.002.029-.008.095-.024c.08-.019.192-.043.332-.067.28-.048.677-.097 1.157-.105.959-.016 2.266.13 3.655.784 1.054.495 2.467.741 3.84.7 1.387-.04 2.6-.367 3.318-.888l.004-.009a.21.21 0 00.015-.082V4.5L16 4.5v7.577z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M3.73 1.094c1.089-.04 2.36.116 3.604.734.985.49 2.014.618 2.936.577a8.235 8.235 0 001.926-.326c.251-.073.502-.155.742-.26A.757.757 0 0114 2.5v8c-.043.586-.686.81-1.343 1.003a9.905 9.905 0 01-2.186.384c-1.131.067-2.48-.067-3.8-.713-.938-.46-1.916-.596-2.803-.574a8.33 8.33 0 00-2.77.566C.61 11.37 0 11.073 0 10.5v-8c0-.337.22-.592.518-.717a9.505 9.505 0 013.212-.69zM1.5 3.016a8.295 8.295 0 012.285-.423c.908-.033 1.916.099 2.881.579 1.27.63 2.567.78 3.67.731a9.707 9.707 0 002.164-.35V9.98a8.448 8.448 0 01-2.117.409c-.95.056-2.026-.06-3.053-.564-1.205-.59-2.435-.752-3.5-.726a9.769 9.769 0 00-2.33.352V3.016z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M7 5.75a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM3.462 8.68c-.458 0-.729.053-1.306.228l-.29-.957c.628-.19 1.003-.272 1.596-.272v1zM11.944 5.036a4.82 4.82 0 01-1.7.277v-1a3.83 3.83 0 001.379-.224l.321.947z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="no-smoke"
          viewBox="0 0 16 17"
        >
          <path
            d="M14.152 7.53c.673-.672.97-1.361.857-2.091-.134-.857-1.083-1.48-1.115-2.312-.007-.174.055-.515.636-1.097L13.47.97c-.742.74-1.104 1.464-1.075 2.215.04 1.021.99 1.57 1.132 2.485.015.1.028.336-.435.8l1.06 1.06z"
          />
          <path
            fill-rule="evenodd"
            d="M12.648 15.622L11.41 13.5h3.84a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75H8.493L5.648 3.622l-1.296.756L6.757 8.5H.75a.75.75 0 00-.75.75v3.5c0 .414.336.75.75.75h8.923l1.68 2.878 1.295-.756zM14.5 12h-3.965l-1.167-2H14.5v2zm-9.25-2h2.382l1.166 2H5.25v-2zm-1.5 0H1.5v2h2.25v-2z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="plus"
          viewBox="0 0 16 16"
        >
          <path d="M8.75 7.25V1h-1.5v6.25H1v1.5h6.25V15h1.5V8.75H15v-1.5H8.75z" />
        </symbol>
        <symbol
          id="refresh"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#121417"
            stroke-width="1.5"
            d="M1 8a6.999 6.999 0 102.282-5.172"
          />
          <path
            stroke="#121417"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M6.116 3.424h-3.23V.194"
          />
        </symbol>
        <symbol
          id="rocket"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.513 3.29a2.25 2.25 0 113.181 3.183A2.25 2.25 0 019.514 3.29zm2.12 1.061a.75.75 0 10-1.06 1.061.75.75 0 001.06-1.06z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M14.39.145c.76.09 1.36.69 1.45 1.45.38 3.2-.591 5.775-2.398 7.663a10.46 10.46 0 01-2.096 1.675l.578 1.695a.75.75 0 01-.132.721l-1.879 2.265a.75.75 0 01-1.226-.104l-1.669-2.882c-.22.049-.442.092-.665.132l-.384.068-2.812-2.811.068-.385c.04-.223.084-.445.132-.665L.475 7.3a.75.75 0 01-.103-1.227l2.264-1.878a.75.75 0 01.72-.133l1.697.578a10.46 10.46 0 011.674-2.095C8.615.737 11.19-.236 14.39.145zm-.04 1.627a.158.158 0 00-.137-.137c-2.765-.329-4.892.502-6.448 1.992-1.498 1.434-2.509 3.52-2.99 5.886l1.697 1.698c2.367-.482 4.453-1.493 5.886-2.99 1.49-1.557 2.321-3.684 1.993-6.449zM4.34 5.981c-.218.48-.41.977-.577 1.488l-1.605-.93 1.113-.923 1.07.365zm4.176 6.241l.93 1.606.923-1.113-.365-1.07c-.48.219-.977.411-1.488.577z"
            clip-rule="evenodd"
          />
          <path d="M3.03 14.03l1.5-1.5-1.06-1.06-1.5 1.5 1.06 1.06zM1.53 15.53l1-1-1.06-1.06-1 1 1.06 1.06z" />
        </symbol>
        <symbol
          id="sanitation-badge"
          viewBox="0 0 16 16"
        >
          <path
            d="M11.495 4.945l1.01 1.11c-1.04.947-1.932 2.102-3.036 2.972-.423.332-.891.242-1.285-.09-.617-.523-1.148-1.154-1.717-1.728l1.066-1.056c.45.455.888.924 1.342 1.376.863-.873 1.712-1.757 2.62-2.584z"
          />
          <path
            fill-rule="evenodd"
            d="M6.446 13.334c.32.235.658.456 1.01.667a3.475 3.475 0 003.588 0c1.548-.926 2.782-2.033 3.628-3.685.838-1.634 1.262-3.732 1.327-6.58A1.685 1.685 0 0014.7 2.052c-1.19-.271-2.07-.697-2.961-1.289a4.439 4.439 0 00-4.978 0c-.89.592-1.772 1.018-2.961 1.289a1.685 1.685 0 00-1.3 1.684c.043 1.845.236 3.374.593 4.664l-.457 1.236-1.802.667c-1.112.41-1.112 1.983 0 2.394l1.802.667.667 1.802c.41 1.112 1.983 1.112 2.394 0l.667-1.802.082-.03zm4.463-11.321a2.94 2.94 0 00-3.317 0c-1.017.675-2.065 1.183-3.459 1.501A.186.186 0 004 3.701c.03 1.287.135 2.377.312 3.312a1.264 1.264 0 011.385.82l.667 1.803 1.802.667c1.105.408 1.112 1.964.02 2.387.644.388 1.436.414 2.088.024 1.383-.828 2.38-1.75 3.064-3.082C14.03 8.28 14.437 6.436 14.5 3.7a.186.186 0 00-.133-.187c-1.394-.317-2.442-.826-3.459-1.501zm-6.902 8.24L4.5 8.92l.493 1.332c.13.35.405.626.754.755l1.333.493-1.333.493c-.349.13-.625.405-.754.755L4.5 14.08l-.493-1.332a1.277 1.277 0 00-.754-.755L1.92 11.5l1.333-.493c.349-.13.625-.405.754-.755z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="search"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.5.25a6.25 6.25 0 103.857 11.168l4.013 4.012 1.06-1.06-4.012-4.013A6.25 6.25 0 006.5.25zM1.75 6.5a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="sn-facebook"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8a8 8 0 10-9.25 7.903v-5.59H4.719V8H6.75V6.238c0-2.005 1.194-3.113 3.022-3.113.875 0 1.79.156 1.79.156V5.25h-1.008c-.994 0-1.304.617-1.304 1.25V8h2.219l-.355 2.313H9.25v5.59A8.002 8.002 0 0016 8z"
          />
        </symbol>
        <symbol
          id="sn-instagram-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 9.833a1.833 1.833 0 110-3.666 1.833 1.833 0 010 3.666z" />
          <path
            fill-rule="evenodd"
            d="M10.223 3.523c-.58-.026-.754-.032-2.223-.032-1.469 0-1.643.006-2.222.032-.537.025-.828.114-1.022.19-.256.1-.44.218-.632.41a1.704 1.704 0 00-.412.633c-.075.194-.164.485-.189 1.022-.026.58-.032.753-.032 2.222 0 1.469.006 1.643.032 2.223.025.536.114.827.19 1.02.1.258.218.44.41.633.193.193.377.312.633.412.194.075.485.165 1.022.189.58.026.753.032 2.222.032 1.469 0 1.643-.006 2.223-.032.536-.024.827-.114 1.02-.19.258-.1.44-.218.633-.41.193-.193.312-.377.412-.633.075-.194.165-.485.189-1.021.026-.58.032-.754.032-2.223 0-1.469-.006-1.643-.032-2.222-.024-.537-.114-.828-.19-1.022-.1-.256-.218-.44-.41-.632a1.703 1.703 0 00-.633-.412c-.194-.075-.485-.164-1.021-.189zM8 5.176a2.824 2.824 0 100 5.648 2.824 2.824 0 000-5.648zm2.936.548a.66.66 0 100-1.32.66.66 0 000 1.32z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M8 16A8 8 0 108 0a8 8 0 000 16zM5.732 2.533C6.32 2.506 6.506 2.5 8 2.5c1.494 0 1.681.006 2.268.033.585.027.985.12 1.335.256.361.14.668.328.974.634.306.306.494.613.634.974.136.35.23.75.256 1.335.027.587.033.774.033 2.268 0 1.494-.006 1.681-.033 2.268-.027.585-.12.985-.256 1.335-.14.361-.328.668-.634.974a2.698 2.698 0 01-.974.634c-.35.136-.75.23-1.335.256-.587.027-.774.033-2.268.033-1.494 0-1.681-.006-2.268-.033-.585-.027-.985-.12-1.335-.256a2.698 2.698 0 01-.974-.634 2.696 2.696 0 01-.634-.974c-.136-.35-.23-.75-.256-1.335C2.506 9.68 2.5 9.494 2.5 8c0-1.494.006-1.681.033-2.268.027-.585.12-.985.256-1.335.14-.361.328-.668.634-.974.306-.306.613-.494.974-.634.35-.136.75-.23 1.335-.256z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="sn-pinterest"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 0a8 8 0 00-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826.066.08.075.149.056.23-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 108 0z"
          />
        </symbol>
        <symbol
          id="sn-tiktok-circle"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 16A8 8 0 108 0a8 8 0 000 16zm.288-6.422c-.032.854-.762 1.531-1.652 1.533-1.13-.009-2.08-1.205-1.465-2.325.378-.69 1.223-.993 1.945-.777l.002-.969.002-.967c-1.649-.25-3.406.733-3.95 2.394-.773 2.357 1.215 4.574 3.461 4.532 2.002 0 3.625-1.557 3.63-3.48V5.668c.798.55 1.756.845 2.739.843l-.004-1.878a2.814 2.814 0 01-2.277-1.175c-.3-.429-.463-.94-.458-1.457H8.288v7.578z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="sn-twitter-circle"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.5319 3L8.92242 7.23432H8.92221L12.8479 13H9.96074L7.3172 9.11739L4.00753 13H3.15216L6.93746 8.55967L3.15216 3H6.03928L8.54246 6.6766L11.6765 3H12.5319ZM7.36734 8.05503L7.75087 8.60865V8.60886L10.3631 12.3797H11.6769L8.47575 7.75859L8.09222 7.20497L5.62953 3.64984H4.31573L7.36734 8.05503Z"
          />
        </symbol>
        <symbol
          id="sn-youtube-circle"
          viewBox="0 0 16 16"
        >
          <path d="M9.75 8.273L6.875 9.846V6.7L9.75 8.273z" />
          <path
            fill-rule="evenodd"
            d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-2.73-2.29a1.356 1.356 0 00-.972-.942C11.44 4.545 8 4.545 8 4.545s-3.44 0-4.298.223a1.356 1.356 0 00-.972.942c-.23.83-.23 2.563-.23 2.563s0 1.732.23 2.563c.126.458.5.819.972.941C4.56 12 8 12 8 12s3.44 0 4.298-.223c.473-.122.846-.483.972-.941.23-.83.23-2.563.23-2.563s0-1.733-.23-2.563z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="sort"
          viewBox="0 0 16 16"
        >
          <path
            d="M11.45.4a.75.75 0 00-.9 0l-4 3 .9 1.2 2.8-2.1V10h1.5V2.5l2.8 2.1.9-1.2-4-3zM.55 12.6l4 3c.267.2.633.2.9 0l4-3-.9-1.2-2.8 2.1V6h-1.5v7.5l-2.8-2.1-.9 1.2z"
          />
        </symbol>
        <symbol
          id="square-check"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M13.47 1.418c.602.23 1.076.908 1.237 1.768C15 4.746 15 8 15 8s0 3.254-.293 4.814c-.16.86-.635 1.538-1.237 1.768C12.378 15 8 15 8 15s-4.378 0-5.47-.418c-.602-.23-1.076-.908-1.237-1.768C1 11.254 1 8 1 8s0-3.254.293-4.814c.16-.86.635-1.538 1.237-1.768C3.622 1 8 1 8 1s4.378 0 5.47.418zm-1.667 3.365L7.357 9.105l-2.16-2.1L3.803 8.44l2.857 2.778a1 1 0 001.394 0l5.143-5-1.394-1.434z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="square-line"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M13.233 3.463c.123.656.195 1.756.231 2.77a48.745 48.745 0 010 3.535c-.036 1.013-.108 2.113-.23 2.77-.131.696-.633.731-1.235.794-.52.054-1.136.092-1.737.117a53.314 53.314 0 01-4.524 0c-.798-.034-1.608-.065-2.396-.206-.381-.068-.506-.335-.575-.705-.247-1.315-.244-2.69-.263-4.026a36.874 36.874 0 010-1.024c.019-1.335.016-2.71.263-4.025.13-.695.635-.732 1.234-.795a29.6 29.6 0 011.737-.117 53.33 53.33 0 014.524 0c.601.025 1.216.063 1.737.117.6.063 1.104.1 1.234.795zM8 1s-4.378 0-5.47.418c-.602.23-1.076.908-1.237 1.768C1 4.746 1 8 1 8s0 3.254.293 4.814c.16.86.635 1.538 1.237 1.768C3.622 15 8 15 8 15s4.378 0 5.47-.418c.602-.23 1.076-.908 1.237-1.768C15 11.254 15 8 15 8s0-3.254-.293-4.814c-.16-.86-.635-1.538-1.237-1.768C12.378 1 8 1 8 1z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="star"
          viewBox="0 0 16 16"
        >
          <path
            d="M9.057 1.361a1.219 1.219 0 00-2.114 0L5.08 4.59l-3.617.79C.542 5.581.193 6.697.809 7.391l2.47 2.789-.374 3.721c-.093.923.841 1.63 1.71 1.243L8 13.64l3.386 1.505c.867.386 1.802-.32 1.71-1.243l-.374-3.721 2.47-2.789c.614-.694.266-1.81-.654-2.01l-3.617-.791L9.057 1.36z"
          />
        </symbol>
        <symbol
          id="trophy"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.5.5h7c.436 0 .686.336.759.727.027.146.075.416.132.773H15a.75.75 0 01.75.75c0 1.845-.394 3.33-1.148 4.376-.682.946-1.63 1.487-2.712 1.601a4.75 4.75 0 01-3.14 1.964v2.578c1.037.054 2.112.22 3.407.498l-.314 1.466c-2.993-.642-4.638-.647-7.687 0l-.312-1.467c1.3-.275 2.377-.44 3.406-.495v-2.58a4.75 4.75 0 01-3.14-1.964c-1.082-.114-2.03-.655-2.712-1.6C.644 6.08.25 4.594.25 2.75A.75.75 0 011 2h2.608c.047-.298.098-.596.156-.892A.757.757 0 014.5.5zm8.159 6.432a2.46 2.46 0 00.726-.683c.428-.593.748-1.493.839-2.749h-1.63c.087.775.156 1.657.156 2.5 0 .319-.031.63-.091.932zM3.25 6c0 .319.031.63.091.932a2.459 2.459 0 01-.727-.683c-.427-.593-.747-1.493-.838-2.749h1.63A22.981 22.981 0 003.25 6zm1.879-4c-.145.874-.379 2.535-.379 4a3.25 3.25 0 106.5 0c0-1.465-.234-3.126-.379-4H5.13z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="user-line"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 2.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM3.75 5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zM8 11.75c-2.665 0-4.993 1.655-5.79 3.992l-1.42-.484C1.805 12.282 4.727 10.25 8 10.25s6.195 2.032 7.21 5.008l-1.42.484c-.797-2.337-3.125-3.992-5.79-3.992z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="users"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M5.5 6.25a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM2.25 8a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM11.5 2.25a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM8.25 4a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM5.5 13.25c-1.28 0-2.403.82-2.788 1.985l-1.424-.47c.59-1.788 2.294-3.015 4.212-3.015 1.918 0 3.622 1.227 4.212 3.015l-1.424.47C7.903 14.071 6.78 13.25 5.5 13.25zM11.5 9.25c-.488 0-.954.12-1.365.333l-.69-1.332c.62-.321 1.321-.501 2.055-.501 1.918 0 3.622 1.227 4.212 3.015l-1.424.47C13.903 10.071 12.78 9.25 11.5 9.25z"
            clip-rule="evenodd"
          />
        </symbol>
        <symbol
          id="wi-fi"
          viewBox="0 0 16 16"
        >
          <path
            d="M8.007 3.39c-2.249-.003-4.542.956-6.547 2.96L.4 5.29c2.246-2.247 4.908-3.404 7.609-3.4 2.7.002 5.356 1.164 7.591 3.4l-1.06 1.06c-1.995-1.995-4.284-2.959-6.533-2.96z"
          />
          <path d="M12.42 8.47c-2.534-2.533-6.311-2.528-8.84 0L2.52 7.41c3.113-3.113 7.84-3.12 10.96 0l-1.06 1.06z" />
          <path
            d="M10.295 10.595c-1.339-1.338-3.243-1.346-4.59 0l-1.06-1.06c1.934-1.935 4.789-1.922 6.71 0l-1.06 1.06zM9.06 11.69a1.5 1.5 0 10-2.12 2.12 1.5 1.5 0 002.12-2.12z"
          />
        </symbol>
      </svg>
    </div>
    <WdsSetup />
  </template>
</template>

<style lang="scss" scoped>
.svg-inline {
  display: none;
}
</style>
