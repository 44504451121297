export function Segment() {
  const testingStore = useTestingStore();
  const instance = window.analytics || [];
  const isSegmentEnabled = testingStore.hasEnabledFeature('web_segment');
  const { isTrackingAllowed } = checkGdpr();

  const segmentIntegration = {
    integrations: {
      'All': false,
      'Actions Google Analytic 4': true,
      'Google Analytics': true,
      'Google Tag Manager': true,
      'Google Analytics 4 Web': true,
      'Facebook Pixel': true,
      'Iterable': true,
      'Iterable (Actions)': true,
      'Talon_One': true,
      'Criteo': true,
      'Button': true,
    },
  };

  const checkIfAbleToSendTracking = (values) => {
    if (!isSegmentEnabled) {
      return false;
    }

    if (!isTrackingAllowed) {
      console.log('Tracking not allowed');
      return false;
    }

    if (typeof values !== 'object') {
      console.error('Segment values are invalid');
      return false;
    }

    if (!instance?.invoked) {
      console.error('Segment not initialized');
      return false;
    }

    if (!window?.analytics?.track && !window?.analytics?.page) {
      console.error('Analytics not present on DOM');
      return false;
    }
    return true;
  };

  return {
    onPageView: (values) => {
      if (checkIfAbleToSendTracking(values)) {
        const eventName = values?.event_name;
        delete values?.event_name;

        window?.analytics?.page(eventName, values, segmentIntegration);
      }
    },

    onEvent: (values) => {
      if (checkIfAbleToSendTracking(values)) {
        const eventName = values?.event_name;
        delete values?.event_name;

        window?.analytics?.track(eventName, values, segmentIntegration);
      }
    },
  };
}
