import { CURRENCY_COOKIE_NAME, DEFAULT_CURRENCY, findCurrencyByCode } from '@/config/currencies';

export default defineNuxtRouteMiddleware(async () => {
  // Skip this middleware on the client side
  if (import.meta.client) {
    return;
  }

  const { getGeoIpInfo } = useApiGeoIp();
  const reqHeaders = useRequestHeaders();
  const router = useRouter();
  const currencyStore = useCurrencyStore();

  const currencyCookie = useCookie(CURRENCY_COOKIE_NAME);
  const routeQueryParams = router?.currentRoute?.value?.query;

  if (routeQueryParams?.currency) {
    // User is forcing a currency via the URL query string
    // Find the currency from group of currencies. If no such currency, default to EUR.
    const currencyToBe = findCurrencyByCode(routeQueryParams?.currency)?.code;
    // Set the currency on the currency store
    currencyStore.setCurrency(currencyToBe);
  } else if (!currencyCookie.value) {
    let currencyToBe = DEFAULT_CURRENCY.code;
    // User is accessing site for the first time OR does not have the 'currency' cookie on the browser
    // Make call to RWD's geoIp endpoint to get the currency associated with the user's IP address
    const userIpAddr = reqHeaders['x-forwarded-for']
      ? reqHeaders['x-forwarded-for'].split(',')[0]
      : reqHeaders.connection?.remoteAddress;
    const geoIpInfo = await getGeoIpInfo(userIpAddr);
    if (geoIpInfo?.currency) {
      currencyToBe = findCurrencyByCode(geoIpInfo?.currency)?.code;
    }

    // Set the currency on the currency store
    currencyStore.setCurrency(currencyToBe);
  }
});
