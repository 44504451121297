const pairSplitRegExp = /; */;
const fieldContentRegExp = /^[\t\u0020-\u007E\u0080-\u00FF]+$/;

function parseCookies(str) {
  if (typeof str !== 'string') {
    throw new TypeError('str must be a string');
  }

  const cookies = {};
  const pairs = str.split(pairSplitRegExp);

  pairs.forEach((pair) => {
    let [key, value] = pair.split('=');

    // Not a key-value pair.
    if (key === undefined || value === undefined) {
      return;
    }

    key = key.trim();
    value = value.trim();

    // Quoted value.
    if (value[0] === '"') {
      value = value.slice(1, -1);
    }

    if (cookies[key] === undefined) {
      cookies[key] = decodeURIComponent(value);
    }
  });

  return cookies;
}

function populateCookies(baseCookie, append) {
  return `${baseCookie}; ${append.join('; ')}`;
}

function writeCookie(str) {
  if (document) {
    document.cookie = str;
  }
}

function setCookieValue(name, value, opts = {}) {
  value = encodeURIComponent(value);
  const str = `${name}=${value}`;

  if (!fieldContentRegExp.test(name)) {
    throw new TypeError('name is invalid');
  }

  const appendToCookie = [];

  if (opts.domain) {
    if (!fieldContentRegExp.test(opts.domain)) {
      throw new TypeError('domain is invalid');
    }
    appendToCookie.push(`domain=${opts.domain}`);
  }

  if (opts.path) {
    if (!fieldContentRegExp.test(opts.path)) {
      throw new TypeError('path is invalid');
    }
    appendToCookie.push(`path=${opts.path}`);
  }

  if (opts.maxAge) {
    if (typeof opts.maxAge !== 'number') {
      throw new TypeError('maxAge must be a number');
    }
    appendToCookie.push(`max-age=${opts.maxAge}`);
  }

  if (opts.expires) {
    if (!fieldContentRegExp.test(opts.expires)) {
      throw new TypeError('expires must be a string');
    }
    appendToCookie.push(`expires=${opts.expires}`);
  }

  if (opts.secure) {
    if (typeof opts.secure !== 'boolean') {
      throw new TypeError('secure must be a boolean');
    }
    appendToCookie.push('secure');
  }

  if (opts.sameSite) {
    appendToCookie.push(`SameSite=${opts.sameSite}`);
  }

  const cookieString = appendToCookie.length ? populateCookies(str, appendToCookie) : str;
  return writeCookie(cookieString);
}

function getCookie(cookieName) {
  const cookies = document?.cookie;

  if (cookies) {
    const cookieArray = parseCookies(cookies);

    if (cookieArray && cookieName in cookieArray && cookieArray[cookieName] !== undefined) {
      return cookieArray[cookieName];
    }
  }
  return null;
}

export const cookies = {
  getCookie,
  setCookieValue,
};
