export function useAppDownloadBanner() {
  const APP_BANNER_FEAT_TOGGLE = 'web_app_download_banner';
  const APP_BANNER_COOKIE_NAME = 'appBannerDismissed';
  const APP_BANNER_MAX_DISMISS = 7 * 86400;

  // ### COMPOSABLES
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;

  // ## STORES
  const testingStore = useTestingStore();

  // ### A/B TESTING FEATURE TOGGLES
  const appBannerEnabled = testingStore.hasEnabledFeature(APP_BANNER_FEAT_TOGGLE);
  const appBannerVars = testingStore.getFeatureVariables(APP_BANNER_FEAT_TOGGLE);

  const appBannerDismissCookie = useCookie(APP_BANNER_COOKIE_NAME, {
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    sameSite: 'Strict',
    secure: true,
    watch: true,
  });

  const isAppDownloadBannerDismissed = function () {
    if (appBannerDismissCookie.value) {
      const timestamp = Math.floor(Date.now() / 1000);
      return timestamp - appBannerDismissCookie.value < APP_BANNER_MAX_DISMISS;
    }

    return false;
  };

  const dismissAppDownloadBanner = function () {
    appBannerDismissCookie.value = Math.floor(Date.now() / 1000);
  };

  const getAppDownloadBannerVariation = function (page) {
    const isAppDownloadBannerEnabledForPage
      = appBannerEnabled && (appBannerVars?.[page] || appBannerVars.staticpages?.[page]);

    if (!isAppDownloadBannerEnabledForPage) {
      return 'control';
    }

    return appBannerVars?.message === 'a' ? 'variation a' : 'variation b';
  };

  return {
    APP_BANNER_FEAT_TOGGLE,
    APP_BANNER_COOKIE_NAME,
    isAppDownloadBannerDismissed,
    dismissAppDownloadBanner,
    getAppDownloadBannerVariation,
  };
}
