export default {
  defaultGuests: {
    mobile: 1,
    desktop: 2,
  },
  defaultNights: 3,
  initialNights: 3,
  maxGuests: 80,
  maxNights: 14,
  extendedMaxNights: 30,
  propertiesPerPage: 30,
  imagesPerProperty: 30,
  limitPropertiesForSocialCues: 30,
  maxRecentSearchesShown: 3,
  recentSearchesExpirationDays: 30,
  defaultIncrementDays: [1, 1, 1, 1, 4, 3, 2],
};
